<template>
  <v-main>
    <v-row no-gutters>
      <v-col md="6" class="public-intro d-none d-md-flex align-center">
        <div class="public-container mx-auto">
          <h1 class="text-center font-weight-medium public-title">Private Banking Reinvented.</h1>
          <v-img
            :src="require('@/assets/img/cometum_laptop.png')"
            alt="cometum-laptop"
            width="350"
            height="250"
            class="public-img"
          />
        </div>
      </v-col>
      <v-col
        class="right-side"
        md="6">
        <div class="logo mt-10">
          <router-link :to="{ name: 'SignIn' }">
            <Logo
              type="horizontal"
              :size="200"
            />
          </router-link>
        </div>
        <v-scroll-x-transition :hide-on-leave="true">
          <router-view />
        </v-scroll-x-transition>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import '@/utils/array';
import Logo from '@/components/Shared/Logo.vue';

export default {
  name: 'PublicContainer',
  components: {
    Logo,
  },
  data() {
    return {
      style: {
        backgroundImage: `url('/img/intro/1.jpg')`,
      },
      images: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  async mounted() {
    this.imageLoop();
  },
  beforeDestroy() {
    clearInterval(this.style);
  },
  methods: {
    imageLoop() {
      setInterval(() => {
        this.style = {
          backgroundImage: `url('/img/intro/${this.images.random()}.jpg')`,
        };
      }, 5000);
    },
  },
};
</script>

<style scoped lang="scss">
.public-intro {
  height: 100vh;
  position: relative;
  background-color: #C9AC85;
}
.public-container {
  position: relative;
  width: 500px;
  margin-top: -220px;
}
.public-title {
  font-size: 69px;
  font-family: 'Playfair Display', sans-serif;
  line-height: 92px;
  color: #EDE1D1;
}
.public-img {
  position: absolute;
  top: 142px;
  left: 74px;
}
.right-side {
  padding: 2em !important;
}
.logo {
  padding-bottom: 2em;
}
</style>
